import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroThankYou from "../components/hero-thankyou";
import Clients from "../components/clients";
import Services from "../components/services";
import Additionals from "../components/additionals";
import Testimonials from "../components/testimonials";

const IndexPage = () => (
    <Layout>
        <SEO title="Catering Service Rio - Cotize su evento hoy misno" />
        <HeroThankYou />
        <Clients />
        <Services />
        <Additionals />
        <Testimonials />
    </Layout>
);

export default IndexPage;

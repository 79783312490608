import React from "react";

import CheckImage from "../images/check.png";

const HeroThankYou = () => {
    return (
        <section id="hero">
            <div className="container">
                <div id="mainForm">
                    <div className="step step-thankyou text-center d-flex flex-column justify-content-center active in">
                        <div className="title">
                            <img
                                src={CheckImage}
                                width={140}
                                className="check"
                                alt="Listo"
                            />
                            <h2>Gracias!</h2>
                            <div className="mt-4">
                                <h4>
                                    Tu información ha sido enviada con éxtito,
                                    <br />
                                    te enviaremos la cotización en las próximas
                                    48 horas
                                </h4>
                                <br/>
                                <h4>Si deseas acelerar tu proceso escríbenos por WhatsApp</h4>
                                <br/>
                                <a href="https://wa.me/50660098104?text=Hola, acabo de solicitar una cotizacion y me gustaria recibiar una propuesta lo antes posible." className="btn btn-success text-white"> 
                                    <i className="fa fa-whatsapp mr-2"></i>
                                    Enviar Mensaje
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroThankYou;
